<template>
  <!-- ApplicationRejectModel Dialog -->
  <v-dialog
    v-model="selfDialog"
    max-width="775"
    max-height="432"
    content-class="no-shadow"
  >
    <template v-slot:default>
      <v-card class="pa-15">
        <v-card-text class="pb-5">
          <template v-if="Array.isArray(text)">
            <div
              v-for="(tt, index) in text"
              :key="index"
              class="
                my-1
                text-center
                d-flex
                justify-center
                align-center
                text-333
              "
              :class="[index == 0 ? 'font-20px mb-7' : 'font-14px']"
              v-html="tt"
            ></div>
          </template>
          <template v-else>
            <div
              class="my-1 d-flex justify-center align-center text-333 font-20px"
              v-html="text"
            ></div>
          </template>

          <v-radio-group v-model="reason" mandatory column class="px-16 pt-8">
            <v-radio
              v-for="(option, index) in reasons"
              :key="index"
              :label="option.name"
              color="primary"
              :value="option.id"
              class="pb-2 text-light-dark"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="!buttonOption.hideCancel"
            outlined
            color="primary"
            min-width="188"
            class="mr-6"
            @click.prevent="selfDialog = false"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn
            v-if="!buttonOption.hideSubmit"
            color="primary"
            class="white--text modal-submit"
            min-width="188"
            @click.prevent="submit()"
            :loading="loading"
          >
            {{ submitButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
  <!-- ApplicationRejectModel Dialog end -->
</template>

<script>
export default {
  name: 'ApplicationRejectModel',
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    text: {
      type: [String, Array],
      default: '',
      required: true,
    },
    reasons: {
      type: [Array, Object],
      required: true,
    },
    submitButtonText: {
      type: String,
      default: '更新',
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonOption: {
      type: [Object],
      default: () => {
        return {
          hideSubmit: false,
          hideCancel: true,
        }
      },
      required: false,
    },
  },
  data() {
    return {
      reason: null,
    }
  },
  computed: {
    selfDialog: {
      get() {
        return this.dialog
      },
      set() {
        return this.$emit('closeModel', true)
      },
    },
  },
  methods: {
    submit() {
      this.$emit('submitSuccess', this.reason)
    },
  },
}
</script>
<style lang="css" scope>
.modal-submit {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
}
</style>
