var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('PageTitle',{attrs:{"items":{
      title: '応募管理',
      subtitle: '一覧',
      tabs: [
        {
          title: '応募済',
          count: _vm.getApplicationCounts.total_applied
            ? _vm.getApplicationCounts.total_applied.value
            : 0,
          notification: _vm.getApplicationCounts.total_applied
            ? _vm.getApplicationCounts.total_applied.admin_read
            : 0,
          action: function () { return _vm.resultOnTab(1); }
        },
        {
          title: '企業対応済',
          count: _vm.getApplicationCounts.business_respond
            ? _vm.getApplicationCounts.business_respond.value
            : 0,
          notification: _vm.getApplicationCounts.business_respond
            ? _vm.getApplicationCounts.business_respond.admin_read
            : 0,
          action: function () { return _vm.resultOnTab(8); }
        },
        {
          title: '合格済',
          count: _vm.getApplicationCounts.total_passed
            ? _vm.getApplicationCounts.total_passed.value
            : 0,
          //notification: getApplicationCounts.total_passed
          //? getApplicationCounts.total_passed.admin_read
          //: 0,
          action: function () { return _vm.resultOnTab(2); }
        },
        {
          title: '完了',
          count: _vm.getApplicationCounts.total_completed
            ? _vm.getApplicationCounts.total_completed.value
            : 0,
          //notification: getApplicationCounts.total_completed
          // ? getApplicationCounts.total_completed.admin_read
          //: 0,
          action: function () { return _vm.resultOnTab(3); }
        },
        {
          title: '不合格',
          count: _vm.getApplicationCounts.total_failed
            ? _vm.getApplicationCounts.total_failed.value
            : 0,
          //notification: getApplicationCounts.total_failed
          // ? getApplicationCounts.total_failed.admin_read
          // : 0,
          action: function () { return _vm.resultOnTab(4); }
        },
        {
          title: '辞退済',
          count: _vm.getApplicationCounts.total_declined
            ? _vm.getApplicationCounts.total_declined.value
            : 0,
          notification: _vm.getApplicationCounts.total_declined
            ? _vm.getApplicationCounts.total_declined.admin_read
            : 0,
          action: function () { return _vm.resultOnTab(5); }
        },
        {
          title: '連絡とれず',
          count: _vm.getApplicationCounts.no_responses
            ? _vm.getApplicationCounts.no_responses.value
            : 0,
          notification: _vm.getApplicationCounts.no_responses
            ? _vm.getApplicationCounts.no_responses.admin_read
            : 0,
          action: function () { return _vm.resultOnTab(6); }
        },
        {
          title: 'その他',
          count: _vm.getApplicationCounts.other_reasons
            ? _vm.getApplicationCounts.other_reasons.value
            : 0,
          notification: _vm.getApplicationCounts.other_reasons
            ? _vm.getApplicationCounts.other_reasons.admin_read
            : 0,
          action: function () { return _vm.resultOnTab(7); }
        }
      ],
      buttons: [
        {
          title: 'CSVエクスポート',
          others: {
            outlined: true
          },
          class: 'bg-white text-ff862f',
          action: function () { return this$1.downloadCsv(); }
        },
        {
          title: '詳細条件検索',
          others: {
            outlined: true
          },
          class: 'bg-white',
          action: function () {
            _vm.toggleSearch = true
          }
        }
      ]
    }}}),_c('v-fade-transition',[(_vm.toggleSearch)?_c('SearchArea',_vm._b({staticClass:"mb-4",on:{"toggleSearch":_vm.updateSearchResults,"changedInputType":function($event){return _vm.changedInputType($event)},"searchSubmit":function($event){return _vm.searchSubmit($event)}}},'SearchArea',{ toggleSearch: _vm.toggleSearch, selectTypeOptions: _vm.selectTypeOptions, searchFields: _vm.searchFields },false)):_vm._e()],1),_c('DataTable',{ref:"pagination",staticClass:"application-table",attrs:{"headers":_vm.getHeaders,"items":_vm.items,"total-records":_vm.getApplicationPagination ? _vm.getApplicationPagination.records_total : 0,"number-of-pages":_vm.getApplicationPagination ? _vm.getApplicationPagination.total_pages : 0,"page":_vm.configuration.page},on:{"update:options":_vm.updateTable},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"position-relative",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(item.id)+" "),_c('div',{staticClass:"position-absolute",staticStyle:{"top":"0%","left":"0px"}},[(!item.is_admin_read)?_c('v-icon',{attrs:{"size":"6px"}},[_vm._v("$notification")]):_vm._e()],1)])]}},{key:"item.job_id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-12px fw-400",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(item.internship_post.id)+" ")]),_c('div',{staticClass:"mouse-pointer"},[_c('router-link',{staticClass:"text-3f74c2 text-decoration-none truncate-lines lines-1",attrs:{"target":"_blank","to":{
            name: 'InternshipPostEdit',
            params: { id: item.internship_post.id }
          }}},[_c('v-tooltip',{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.internship_post.title))])]}}],null,true)},[_c('span',{staticClass:"text-333"},[_vm._v(" "+_vm._s(item.internship_post.title))])])],1)],1)]}},{key:"item.company_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-12px fw-400",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(item.company.internal_company_id)+" ")]),_c('div',{staticClass:"mouse-pointer"},[_c('router-link',{staticClass:"text-3f74c2 text-decoration-none truncate-lines lines-1",attrs:{"target":"_blank","to":{
            name: 'CorporateEdit',
            params: { id: item.company.id }
          }}},[_c('v-tooltip',{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.company.name))])]}}],null,true)},[_c('span',{staticClass:"text-333"},[_vm._v(" "+_vm._s(item.company.name))])])],1)],1)]}},{key:"item.student_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-12px fw-400",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(item.student.student_internal_id)+" ")]),_c('div',{staticClass:"mouse-pointer"},[_c('router-link',{staticClass:"text-3f74c2 text-decoration-none truncate-lines lines-1",attrs:{"target":"_blank","to":{
            name: 'StudentProfile',
            params: { id: item.student.id }
          }}},[_c('v-tooltip',{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.student.family_name)+" "+_vm._s(item.student.first_name))])]}}],null,true)},[_c('span',{staticClass:"text-333"},[_vm._v(" "+_vm._s(item.student.family_name)+" "+_vm._s(item.student.first_name))])])],1)],1)]}},{key:"item.university",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-12px fw-400",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(item.student.education_facility.name)+" ")]),_c('div',{staticClass:"truncate-lines lines-1",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(item.student.obfuscate_email)+" ")])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-12px fw-400",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")])]}},{key:"item.cancel_reason",fn:function(ref){
          var item = ref.item;
return [(item.cancel_reason)?_c('v-tooltip',{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({staticClass:"font-12px fw-400 truncate-lines lines-1",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},on),[_vm._v(" "+_vm._s(_vm.get_reason_text(item.cancel_reason))+" ")])]}}],null,true)},[_c('span',{staticClass:"text-333",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(_vm._s(_vm.get_reason_text(item.cancel_reason)))])]):_vm._e()]}},{key:"item.update_date",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-12px fw-400",class:item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''},[_vm._v(" "+_vm._s(_vm.dateFormat(item.updated_at))+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-sheet',{staticClass:"d-flex align-center justify-center",attrs:{"color":"transparent"}},[_c('v-menu',{attrs:{"bottom":"","close-on-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-12px white--text py-2",class:item.status === 6 || item.status === 8 ? 'pill-large' : 'pill',attrs:{"pill":"","dense":"","color":_vm.chipColor(item.status)}},'v-chip',attrs,false),on),[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"text-truncate mr-2"},[_vm._v(" "+_vm._s(_vm._f("status")(item.status))+" ")]),_c('v-icon',{attrs:{"size":"8px","color":"white"}},[_vm._v("$dropdown")])],1)])]}}],null,true)},[_c('v-list',_vm._l((_vm.options),function(option,index){return _c('v-list-item',{key:index,staticClass:"mouse-pointer font-12px fw-400",on:{"click":function($event){return _vm.statusChange(item, option)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("status")(option)))])],1)}),1)],1),(item.status === 2 || item.status === 3)?_c('v-icon',{staticClass:"ml-7",attrs:{"size":"21px"},on:{"click":function($event){return _vm.$router.push({
              name: 'FeedbackCreate',
              params: {
                company_id: item.company.internal_company_id,
                student_id: item.student.student_internal_id
              }
            })}}},[_vm._v("$message ")]):_vm._e()],1)]}}])}),_c('ApplicationRejectModel',{attrs:{"text":"辞退の理由を選択してください","dialog":_vm.dialog.reject,"reasons":_vm.getReasons},on:{"submitSuccess":_vm.setDecline,"closeModel":function($event){_vm.dialog.reject = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }