<template>
  <div class="">
    <PageTitle
      :items="{
        title: '応募管理',
        subtitle: '一覧',
        tabs: [
          {
            title: '応募済',
            count: getApplicationCounts.total_applied
              ? getApplicationCounts.total_applied.value
              : 0,
            notification: getApplicationCounts.total_applied
              ? getApplicationCounts.total_applied.admin_read
              : 0,
            action: () => resultOnTab(1)
          },
          {
            title: '企業対応済',
            count: getApplicationCounts.business_respond
              ? getApplicationCounts.business_respond.value
              : 0,
            notification: getApplicationCounts.business_respond
              ? getApplicationCounts.business_respond.admin_read
              : 0,
            action: () => resultOnTab(8)
          },
          {
            title: '合格済',
            count: getApplicationCounts.total_passed
              ? getApplicationCounts.total_passed.value
              : 0,
            //notification: getApplicationCounts.total_passed
            //? getApplicationCounts.total_passed.admin_read
            //: 0,
            action: () => resultOnTab(2)
          },
          {
            title: '完了',
            count: getApplicationCounts.total_completed
              ? getApplicationCounts.total_completed.value
              : 0,
            //notification: getApplicationCounts.total_completed
            // ? getApplicationCounts.total_completed.admin_read
            //: 0,
            action: () => resultOnTab(3)
          },
          {
            title: '不合格',
            count: getApplicationCounts.total_failed
              ? getApplicationCounts.total_failed.value
              : 0,
            //notification: getApplicationCounts.total_failed
            // ? getApplicationCounts.total_failed.admin_read
            // : 0,
            action: () => resultOnTab(4)
          },
          {
            title: '辞退済',
            count: getApplicationCounts.total_declined
              ? getApplicationCounts.total_declined.value
              : 0,
            notification: getApplicationCounts.total_declined
              ? getApplicationCounts.total_declined.admin_read
              : 0,
            action: () => resultOnTab(5)
          },
          {
            title: '連絡とれず',
            count: getApplicationCounts.no_responses
              ? getApplicationCounts.no_responses.value
              : 0,
            notification: getApplicationCounts.no_responses
              ? getApplicationCounts.no_responses.admin_read
              : 0,
            action: () => resultOnTab(6)
          },
          {
            title: 'その他',
            count: getApplicationCounts.other_reasons
              ? getApplicationCounts.other_reasons.value
              : 0,
            notification: getApplicationCounts.other_reasons
              ? getApplicationCounts.other_reasons.admin_read
              : 0,
            action: () => resultOnTab(7)
          }
        ],
        buttons: [
          {
            title: 'CSVエクスポート',
            others: {
              outlined: true
            },
            class: 'bg-white text-ff862f',
            action: () => this.downloadCsv()
          },
          {
            title: '詳細条件検索',
            others: {
              outlined: true
            },
            class: 'bg-white',
            action: () => {
              toggleSearch = true
            }
          }
        ]
      }"
    ></PageTitle>
    <v-fade-transition>
      <SearchArea
        class="mb-4"
        v-if="toggleSearch"
        v-bind="{ toggleSearch, selectTypeOptions, searchFields }"
        @toggleSearch="updateSearchResults"
        @changedInputType="changedInputType($event)"
        @searchSubmit="searchSubmit($event)"
      ></SearchArea>
    </v-fade-transition>
    <DataTable
      :headers="getHeaders"
      :items="items"
      :total-records="
        getApplicationPagination ? getApplicationPagination.records_total : 0
      "
      :number-of-pages="
        getApplicationPagination ? getApplicationPagination.total_pages : 0
      "
      @update:options="updateTable"
      :page="configuration.page"
      ref="pagination"
      class="application-table"
    >
      <template v-slot:item.id="{ item }">
        <div
          class="position-relative"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ item.id }}
          <div class="position-absolute" style="top: 0%; left: 0px">
            <v-icon size="6px" v-if="!item.is_admin_read">$notification</v-icon>
          </div>
        </div>
      </template>

      <template v-slot:item.job_id="{ item }">
        <div
          class="font-12px fw-400"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ item.internship_post.id }}
        </div>
        <div class="mouse-pointer">
          <router-link
            target="_blank"
            class="text-3f74c2 text-decoration-none truncate-lines lines-1"
            :to="{
              name: 'InternshipPostEdit',
              params: { id: item.internship_post.id }
            }"
          >
            <v-tooltip top color="white">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.internship_post.title }}</span>
              </template>
              <span class="text-333"> {{ item.internship_post.title }}</span>
            </v-tooltip>
          </router-link>
        </div>
      </template>

      <template v-slot:item.company_id="{ item }">
        <div
          class="font-12px fw-400"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ item.company.internal_company_id }}
        </div>
        <div class="mouse-pointer">
          <router-link
            target="_blank"
            class="text-3f74c2 text-decoration-none truncate-lines lines-1"
            :to="{
              name: 'CorporateEdit',
              params: { id: item.company.id }
            }"
          >
            <v-tooltip top color="white">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.company.name }}</span>
              </template>
              <span class="text-333"> {{ item.company.name }}</span>
            </v-tooltip>
          </router-link>
        </div>
      </template>
      <template v-slot:item.student_id="{ item }">
        <div
          class="font-12px fw-400"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ item.student.student_internal_id }}
        </div>
        <div class="mouse-pointer">
          <router-link
            target="_blank"
            class="text-3f74c2 text-decoration-none truncate-lines lines-1"
            :to="{
              name: 'StudentProfile',
              params: { id: item.student.id }
            }"
          >
            <v-tooltip top color="white">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ item.student.family_name }}
                  {{ item.student.first_name }}</span
                >
              </template>
              <span class="text-333">
                {{ item.student.family_name }}
                {{ item.student.first_name }}</span
              >
            </v-tooltip>
          </router-link>
        </div>
      </template>
      <template v-slot:item.university="{ item }">
        <div
          class="font-12px fw-400"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ item.student.education_facility.name }}
        </div>
        <div
          class="truncate-lines lines-1"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ item.student.obfuscate_email }}
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <div
          class="font-12px fw-400"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ dateFormat(item.created_at) }}
        </div>
      </template>
      <template v-slot:item.cancel_reason="{ item }">
        <v-tooltip v-if="item.cancel_reason" top color="white">
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
              class="font-12px fw-400 truncate-lines lines-1"
              :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
            >
              {{ get_reason_text(item.cancel_reason) }}
            </div>
          </template>
          <span
            class="text-333"
            :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
            >{{ get_reason_text(item.cancel_reason) }}</span
          >
        </v-tooltip>
      </template>
      <template v-slot:item.update_date="{ item }">
        <div
          class="font-12px fw-400"
          :class="item.student.reason_for_withdrawal ? 'text-b8b8b8' : ''"
        >
          {{ dateFormat(item.updated_at) }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-sheet color="transparent" class="d-flex align-center justify-center">
          <v-menu bottom :close-on-click="true" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="font-12px white--text py-2"
                :class="
                  item.status === 6 || item.status === 8 ? 'pill-large' : 'pill'
                "
                pill
                dense
                v-on="on"
                v-bind="attrs"
                :color="chipColor(item.status)"
              >
                <div class="d-flex align-center justify-space-between">
                  <div class="text-truncate mr-2">
                    {{ item.status | status }}
                  </div>
                  <v-icon size="8px" color="white">$dropdown</v-icon>
                </div>
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                class="mouse-pointer font-12px fw-400"
                v-for="(option, index) in options"
                :key="index"
                @click="statusChange(item, option)"
              >
                <v-list-item-title>{{ option | status }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon
            size="21px"
            class="ml-7"
            @click="
              $router.push({
                name: 'FeedbackCreate',
                params: {
                  company_id: item.company.internal_company_id,
                  student_id: item.student.student_internal_id
                }
              })
            "
            v-if="item.status === 2 || item.status === 3"
            >$message
          </v-icon>
        </v-sheet>
      </template>
    </DataTable>
    <ApplicationRejectModel
      text="辞退の理由を選択してください"
      :dialog="dialog.reject"
      :reasons="getReasons"
      @submitSuccess="setDecline"
      @closeModel="dialog.reject = false"
    ></ApplicationRejectModel>
  </div>
</template>
<script>
import DataTable from '@/components/ui/DataTable.vue'
import SearchArea from '@/components/ui/SearchArea'
import ApplicationRejectModel from '@/components/models/ApplicationRejectModel'
import { mapGetters } from 'vuex'

export default {
  components: { DataTable, SearchArea, ApplicationRejectModel },
  filters: {
    status: function(value) {
      if (parseInt(value) === 1) {
        return '応募済'
      }
      if (parseInt(value) === 2) {
        return '合格済'
      }
      if (parseInt(value) === 3) {
        return '完了'
      }
      if (parseInt(value) === 4) {
        return '不合格'
      }
      if (parseInt(value) === 5) {
        return '辞退済'
      }
      if (parseInt(value) === 6) {
        return '連絡とれず'
      }
      if (parseInt(value) === 7) {
        return 'その他'
      }
      if (parseInt(value) === 8) {
        return '企業対応済'
      }
    }
  },
  data() {
    return {
      selection: 1,
      toggleSearch: false,
      options: [1, 8, 2, 3, 4, 5, 6, 7],
      items: [],
      declineItem: [],
      totalPages: 0,
      totalRecords: 0,
      searchFields: [],
      activeStatus: 1,
      selectTypeOptions: [
        {
          id: 'keyword_search',
          name: 'キーワード検索'
        },
        {
          id: 'created_at',
          name: '応募日'
        }
      ],
      dialog: {
        reject: false
      },
      configuration: {
        page: 1,
        sort_by: 'updated_at',
        sort_by_order: 'desc',
        paginate: 25
      },
      setTimeOutRef: null,
      declineAdminRead: 0,
      searchQuery: {}
    }
  },
  computed: {
    ...mapGetters([
      'getAllApplication',
      'getApplicationPagination',
      'getApplicationCsvData',
      'getApplicationCounts'
    ]),
    getHeaders() {
      if (this.selection < 5 || this.selection >= 6) {
        return [
          {
            text: 'ID',
            value: 'id',
            align: 'center',
            class: ['px-0'],
            sortable: false,
            width: '3.9%'
          },
          {
            text: '求人ID',
            subText: '求人タイトル',
            value: 'job_id',
            align: 'left',
            class: ['py-3', 'px-0'],
            sortable: false,
            width: '23.3%'
          },
          {
            text: '企業ID',
            subText: '企業名',
            align: 'left',
            class: ['px-0'],
            value: 'company_id',
            sortable: false,
            width: '15.43%'
          },
          {
            text: '学生ID',
            subText: '学生名',
            class: ['px-0'],
            value: 'student_id',
            align: 'left',
            sortable: false,
            width: '12.194%'
          },
          {
            text: '大学名',
            subText: '学生メールアドレス',
            value: 'university',
            class: ['px-0'],
            align: 'left',
            sortable: false,
            width: '16%'
          },
          {
            text: '応募日',
            sortable: true,
            align: 'left',
            class: ['px-0'],
            value: 'created_at',
            width: '10.90%'
          },
          {
            text: '更新日',
            sortable: false,
            align: 'left',
            class: ['px-0'],
            value: 'update_date',
            width: '9.22%'
          },
          {
            text: '',
            value: 'status',
            class: ['px-0'],
            sortable: false,
            align: 'left',
            width: '8.33%'
          }
        ]
      }
      return [
        {
          text: 'ID',
          value: 'id',
          align: 'center',
          class: ['px-0'],
          sortable: false,
          width: '3.9%'
        },
        {
          text: '企業ID',
          subText: '求人タイトル',
          value: 'job_id',
          align: 'left',
          class: ['py-3', 'px-0'],
          sortable: false,
          width: '19.3%'
        },
        {
          text: '企業ID',
          subText: '企業名',
          align: 'left',
          class: ['px-0'],
          value: 'company_id',
          sortable: false,
          width: '15.43%'
        },
        {
          text: '学生ID',
          subText: '学生名',
          class: ['px-0'],
          value: 'student_id',
          align: 'left',
          sortable: false,
          width: '12.194%'
        },
        {
          text: '大学名',
          subText: '学生メールアドレス',
          value: 'university',
          class: ['px-0'],
          align: 'left',
          sortable: false,
          width: '18%'
        },
        {
          text: '応募日',
          sortable: true,
          align: 'left',
          class: ['px-0'],
          value: 'created_at',
          width: '10.90%'
        },
        {
          text: '辞退理由',
          sortable: false,
          align: 'left',
          class: ['px-0'],
          value: 'cancel_reason',
          width: '16%'
        },
        {
          text: '更新日',
          sortable: false,
          align: 'left',
          class: ['px-0'],
          value: 'update_date',
          width: '9.22%'
        },
        {
          text: '',
          value: 'status',
          sortable: false,
          align: 'left',
          width: '10.33%'
        }
      ]
    },
    getReasons() {
      return this.getMasterData?.cancel_reasons
    }
  },
  created() {
    this.changedInputType('keyword_search')
  },
  beforeDestroy() {
    this.removeTimeoutRef()
  },
  methods: {
    async updateSearchResults() {
      this.toggleSearch = false
      this.configuration.page = 1
      this.searchQuery = {}
      await this.generateItems()
      this.resetPagination()
    },
    async resultOnTab(tab) {
      this.configuration.page = 1
      this.selection = tab
      await this.generateItems()
      this.resetPagination()
    },
    removeTimeoutRef() {
      if (this.setTimeOutRef) {
        clearTimeout(this.setTimeOutRef)
        this.setTimeOutRef = null
      }
    },
    updateAdminRead() {
      const applicationsTobeUpdated = this.items
        .filter(application => application.is_admin_read == 0)
        .map(application => application.id)
      if (applicationsTobeUpdated.length > 0) {
        this.setTimeOutRef = setTimeout(async () => {
          await this.$store.dispatch('UPDATE_APPLICATION_ADMIN_READ', {
            application_ids: applicationsTobeUpdated
          })
          this.items.forEach(item => {
            const findId = applicationsTobeUpdated.find(id => item.id === id)
            if (findId) {
              item.is_admin_read = 1
            }
          })
        }, 5000)
      }
    },
    get_reason_text(value) {
      if (value) {
        return this.getReasons.find(item => item.id == value).name
      }
    },
    setDecline(reason) {
      this.declineItem.cancel_reason = reason
      this.dialog.reject = false
      this.updateApi(this.declineItem, 5)
    },
    async statusChange(item, option) {
      if (item.status == option) return false
      if (option == '5') {
        this.declineItem = item
        this.dialog.reject = true
        return false
      }
      this.updateApi(item, option)
    },
    async updateApi(item, option) {
      let params = {
        id: item.id,
        company_id: item.company_id,
        student_id: item.student_id,
        internship_post_id: item.internship_post_id,
        is_admin_read: item.is_admin_read,
        cancel_reason: item.cancel_reason ? item.cancel_reason : null,
        status: option
      }
      await this.$store.dispatch('APPLICATION_UPDATE', params)
      this.generateItems()
    },
    updateTable(e) {
      this.configuration.sort_by = e.sortBy.length ? e.sortBy[0] : 'updated_at'
      this.configuration.sort_by_order = e.sortDesc[0] ? 'asc' : 'desc'
      this.configuration.page = e?.page ? e.page : 1
      this.generateItems()
    },
    async downloadCsv() {
      await this.$store.dispatch('APPLICATION_EXPORT_CSV')
      let fileUrl = window.URL.createObjectURL(
        new Blob([this.getApplicationCsvData], {
          type: 'text/plain;charset=UTF-8'
        })
      )
      let fileLink = document.createElement('a')

      fileLink.href = fileUrl
      fileLink.setAttribute(
        'download',
        ` 応募情報_${this.$moment().format('YYYYMMDD')}.csv`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      document.body.removeChild(fileLink)
    },

    changedInputType(event) {
      switch (event) {
        case 'keyword_search':
          this.searchFields = [
            {
              label: 'Search text',
              name: 'search',
              type: 'text',
              value: null,
              placeholder: '企業ID、企業名、学生ID、学生名'
            }
          ]
          break
        case 'created_at':
          this.searchFields = [
            {
              label: 'Label',
              name: 'date_from',
              type: 'date',
              rules: 'required',
              show_after_approx: true,
              value: `${this.$moment().format('YYYY-MM-DD')}`,
              menu: false,
              locale: 'ja',
              date_format: 'YYYY-MM-DD 00:00'
            },
            {
              label: 'Label',
              name: 'date_to',
              type: 'date',
              rules: 'required',
              show_after_approx: false,
              value: `${this.$moment().format('YYYY-MM-DD')}`,
              menu: false,
              locale: 'ja',
              range: true,
              range_input: 'date_from',
              date_format: 'YYYY-MM-DD 00:00'
            }
          ]
      }
    },
    chipColor(status) {
      if (status === 1) {
        return '#EE6C9B'
      }
      if (status === 2) {
        return '#5E94E4'
      }
      if (status === 3) {
        return '#60D1CB'
      }
      if (status === 4) {
        return '#A7A7A7'
      }
      if (status === 5) {
        return '#C771B5'
      }
      if (status === 6 || status === 7) {
        return '#666666'
      }
      if (status === 8) {
        return '#dc8360'
      }
    },
    async generateItems(obj = {}) {
      this.loading = true
      this.items = []
      this.configuration.status = this.selection
      if (!this.searchQuery?.search) {
        delete this.configuration.search
      } else {
        this.configuration.search = this.searchQuery?.search
      }
      if (
        this.searchQuery?.date_from?.length > 0 &&
        this.searchQuery?.date_to?.length > 0
      ) {
        this.configuration.date_from = this.searchQuery?.date_from
        this.configuration.date_to = this.searchQuery?.date_to
      } else {
        delete this.configuration.date_from
        delete this.configuration.date_to
      }

      let response = await this.$store.dispatch(
        'APPLICATION_GET_ALL',
        this.configuration
      )

      this.totalPages = response.data.data.paginate.total_pages
      this.totalRecords = response.data.data.paginate.records_total
      this.items = response.data.data.data
      this.total = response.data.total
      this.removeTimeoutRef()
      if (response) {
        this.updateAdminRead()
      }
      this.loading = false
    },
    resetPagination() {
      this.$refs.pagination.currentPage = 1
      ++this.$refs.pagination.updatePaginate
    },
    async searchSubmit($event) {
      if ($event.fields.length > 0) {
        $event.fields.forEach(field => {
          this.searchQuery[field.name] = field.value
        })
        this.configuration.page = 1
        await this.generateItems()
        this.resetPagination()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pill {
  width: 77px;
  height: 30px;
  border-radius: 30px;
}

.pill-large {
  width: 96px;
  height: 30px;
  border-radius: 30px;
}

.application-table ::v-deep {
  thead {
    th {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  tbody {
    tr {
      td {
        padding-top: 15px !important;
        padding-bottom: 15px !important;

        &:nth-child(2) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}
</style>
